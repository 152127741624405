<template>
  <ion-list v-if="resources != null">
    <ion-item-sliding
      v-for="item in resources.data"
      :key="item.id"
      :ref="`items-${item.id}`"
    >
      <ion-item>
        <ion-label>
          <h2>Standard {{ item.name }}</h2>
        </ion-label>
        <ion-buttons slot="end">
          <ion-button @click="slide(item.id)">
            <ion-icon
              slot="icon-only"
              :ios="ellipsisHorizontalOutline"
              :md="ellipsisHorizontalSharp"
              size="small"
            ></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-item>
      <ion-item-options side="start">
        <ion-item-option color="light" @click="setResourceToUpdate(item.id)">
          <ion-icon
            slot="icon-only"
            :ios="createOutline"
            :md="createSharp"
            size="small"
          ></ion-icon>
        </ion-item-option>
        <ion-item-option
          color="light"
          @click="confirmDelete({ id: item.id, name: item.name })"
        >
          <ion-icon
            slot="icon-only"
            :ios="trashOutline"
            :md="trashSharp"
            size="small"
            style="color: #eb445a"
          ></ion-icon>
        </ion-item-option>
      </ion-item-options>
    </ion-item-sliding>
  </ion-list>
  <ion-list v-else>
    <ion-list-header>
      <p>No standards found</p>
    </ion-list-header>
  </ion-list>
  <ion-modal
    :is-open="updateModalState"
    @didDismiss="setUpdateModalState(false)"
  >
    <base-modal title="Update section" @closeModal="setUpdateModalState(false)">
      <update-standard
        @closeModal="setUpdateModalState(false)"
        :resource="resourceToUpdate"
      ></update-standard>
    </base-modal>
  </ion-modal>
</template>
<script>
import BaseModal from "../../../Base/BaseModal";
import UpdateStandard from "../Standard/UpdateStandard";
import {
  IonList,
  IonListHeader,
  IonLabel,
  IonItemSliding,
  IonItemOptions,
  IonItemOption,
  IonItem,
  IonButtons,
  IonButton,
  IonIcon,
  IonModal,
  alertController,
} from "@ionic/vue";

import {
  trashOutline,
  trashSharp,
  createOutline,
  createSharp,
  ellipsisHorizontalOutline,
  ellipsisHorizontalSharp,
} from "ionicons/icons";

export default {
  props: {
    resources: {
      type: Object,
      required: true,
    },
  },

  components: {
    IonList,
    IonListHeader,
    IonLabel,
    IonItemSliding,
    IonItemOptions,
    IonItemOption,
    IonItem,
    IonButtons,
    IonButton,
    IonIcon,
    IonModal,
    BaseModal,
    UpdateStandard,
  },
  data() {
    return {
      trashOutline,
      trashSharp,
      createOutline,
      createSharp,
      ellipsisHorizontalOutline,
      ellipsisHorizontalSharp,

      updateModalState: false,

      resourceToUpdate: {
        id: null,
        name: null,
        hierachy: null,
      },

      xhrError: {
        header: null,
        description: null,
      },
    };
  },
  methods: {
    alerter(header, message, buttons = ["close"]) {
      const alert = alertController.create({
        header: header,
        message: message,
        buttons: buttons,
      });

      return alert;
    },

    setErrorResponse(error) {
      if (error.response) {
        this.xhrError.header = error.response.data.header;
        this.xhrError.message = error.response.data.message;
      } else if (error.request) {
        this.xhrError.header = error.message || error.request;
        this.xhrError.message = "Please check your connection and try again";
      } else {
        this.xhrError.header = error.message;
        this.xhrError.message = "Something went wrong. Try again later";
      }
    },

    async confirmDelete(resource) {
      this.setPopoverState(false);
      const header = `Delete standard ${resource.name} ?`;
      const message = "This action cannot be undone.";
      const buttons = [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
        },
        {
          text: "Okay",
          handler: () => {
            this.delete(resource.id);
          },
        },
      ];
      const alertDialog = await this.alerter(header, message, buttons);

      alertDialog.present();
    },

    async delete(data) {
      try {
        await this.$store.dispatch("standard/deleteResource", data);
      } catch (error) {
        this.setErrorResponse(error);
        const alertDialog = await this.alerter(
          this.xhrError.header,
          this.xhrError.message
        );
        alertDialog.present();
      }
    },

    setUpdateModalState(state) {
      this.updateModalState = state;
      if (state == false) {
        this.setPopoverState(false);
      }
    },

    setResourceToUpdate(id) {
      const resource = this.resources.data.find(
        (resource) => resource.id === id
      );
      this.resourceToUpdate = resource;
      this.setUpdateModalState(true);
    },
  },
};
</script>
